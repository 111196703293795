<template>
    <div class="container-pop-up-card">
      <b-modal ref="modal" size="sm" centered @hide="modalHidden" hide-footer>
        <PopUpCard
          v-if="!isCancelFileDeletion"
          :titleCard="titleCard"
          :textMsg="textMsg"
          :closePopUp="closePopUp"
          :isOk="isOk"
          :isPopupActive="isPopupActive"
          :deleteFileById="deleteFileById"
          :isDelete="isDelete"
          :cancelFileDeletion="cancelFileDeletion"
          :isYearRepeated="isYearRepeated"
          :csvOverwrite="csvOverwrite"
          @save-objectives-clicked="handleSaveObjectives"
          :isSaveObjectives="isSaveObjectives"
          :isInviteCoworker="isInviteCoworker"
          :sendInviteCoworker="sendInviteCoworker"
          :isSendingInvite="isSendingInvite"
          :isDeletingFile="isDeletingFile"
        />
        <PopUpCard
          v-else
          :titleCard="'Action cancelled'"
          :textMsg="'The action has been canceled.'"
          :closePopUp="closePopUp"
          :isOk="true"
          :isPopupActive="true"
        />
      </b-modal>
    </div>
  </template>

  <script>
  import PopUpCard from './PopUpCard.vue';
  import { BModal } from 'bootstrap-vue';
  
  export default {
    name: 'ModalWithPopUpCard',
    components: {
      PopUpCard,
      BModal,
    },
    props: {
      titleCard: {
        type: String,
        required: true,
      },
      textMsg: {
        type: String,
        required: true,
      },
      isOk: {
        type: Boolean,
        default: false,
      },
      isDelete: {
        type: Boolean,
        default: false,
      },
      deleteFileById: {
        type: Function,
      },
      isYearRepeated: {
        type: Boolean,
      },
      csvOverwrite: {
        type: Function,
      },
      userInputSubComp: {
        type: Object,
      },
      isSaveObjectives: {
        type: Boolean,
      },
      isInviteCoworker: {
        type: Boolean,
      },
      sendInviteCoworker: {
        type: Function,
      },
      isSendingInvite: {
        type: Boolean, 
      },
      isDeletingFile: {
        type: Boolean,
      },
    },
    data() {
      return {
        isPopupActive: false,
        isCancelFileDeletion: false,
      };
    },
    methods: {
      modalHidden() {
        document.removeEventListener('keydown', this.handleKeydown);
      },
      cancelFileDeletion() {
       this.isCancelFileDeletion = true;
      },
      closePopUp() {
        this.isPopupActive = false;
        this.isCancelFileDeletion = false;
        this.$refs.modal.hide();
      },
      handleKeydown(event) {
        if (event.key === 'Enter') {
          this.$refs.modal.hide();
          this.isPopupActive = false;
          this.isCancelFileDeletion = false;
        }
      },
      showModal() {
        this.isPopupActive = true;
        this.$refs.modal.show();
      },
      handleSaveObjectives(userInputSubComp) {
        this.$emit('save-objectives-clicked', userInputSubComp);
      },
    },
    watch: {
      isPopupActive(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            document.addEventListener('keydown', this.handleKeydown);
          });
        }
      },
    },
  };
  </script>
  