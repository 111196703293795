<template>
  <div class="popup-data-uploaded" v-if="isPopupActive" :style="{ minWidth: minWidth }">
    <div class="popup-data-content">
      <div class="popup-info">
        <div class="popup-top">
          <h1 class="title-card">{{ titleCard }}</h1>
          <span @click="closePopUp" class="closeBtn">x</span>
        </div>
        <p class="text-msg" v-html="textMsg"></p>
      </div>
      <div class="popup-options">
        <div class="container-ok-opt" v-if="isOk">
          <p @click="closePopUp">OK</p>
        </div>

        <div v-if="isDelete" class="container-footer-opt">
          <p @click="cancelFileDeletion">Cancel</p>
          <button class="delete-file-popup-button" @click="deleteFileById(idFile)" :disabled="isDeletingFile">OK</button>
        </div>
        
        <div v-if="isYearRepeated" class="container-footer-opt">
          <p @click="closePopUp">Cancel</p>
          <p @click="csvOverwrite">Ok</p>
        </div>

        <div v-if="isSaveObjectives" class="container-footer-opt">
          <p @click="closePopUp" class="text-cancel-popup-button">Cancel</p>
          <p @click="saveObjectivesClicked">Save</p>
        </div>

        <div v-if="isInviteCoworker" class="container-footer-opt">
          <button @click="closePopUp" class="text-cancel-popup-button">Cancel</button>
          <button class="send-popup-button" @click="sendInviteCoworker" :disabled="isSendingInvite">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopUpCard',
  props: {
    titleCard: {
      type: String,
      required: true,
    },
    textMsg: {
      type: String,
      required: true,
    },
    isPopupActive: {
      type: Boolean,
    },
    closePopUp: {
      type: Function,
      required: true,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    deleteFileById: {
      type: Function,
    },
    idFile: {
      type: String,
    },
    isYearRepeated: {
      type: Boolean,
    },
    csvOverwrite: {
      type: Function,
    },
    cancelFileDeletion: {
      type: Function,
    },
    sendInviteCoworker: {
      type: Function,
    },
    userInputSubComp: {
      type: String,
    },
    isSaveObjectives: {
      type: Boolean,
    },
    isInviteCoworker: {
      type: Boolean,
    },
    minWidth: {
      type: String,
      default: '300px',
    },
    isSendingInvite: {
      type: Boolean,
    },
    isDeletingFile: {
      type: Boolean,
    },
  },
  methods: {
    saveObjectivesClicked() {
      this.$emit('save-objectives-clicked', this.userInputSubComp);
      this.closePopUp();
    },
  },
};
</script>

<style src="../../assets/styles/components/shared/PopUpCard.css"></style>
