<template>
    <div class="instructions">
        <h1 class="title">Instructions sent</h1>

        <div class="px-5 pb-5">
            <!-- <p class="subtitle pt-5">If your account with Alice is with the email address {{ email }},<br>
                instructions to reset your password will be sent to it.</p> -->
                <p class="subtitle pt-5">An email has been to {{ email }}.<br></p>
            <p class="subtitle">Please follow the instructions in the email to reset your password.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "InstructionsSent",
    components: {},
    props: {
        email: {
            type: String,
        }
    },
}
</script>

<style scoped>
.instructions {
    color: var(--color-purple);
    font-family: Helvetica;
    font-weight: 300;
}

.title {
    font-size: 28px;
}

.subtitle {
    font-size: 19px;
}
</style>
