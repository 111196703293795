import { api } from "../api/stagingApi.js";

export async function loginUser({ email, password }) {
  const response = await api.post(`/user/login`, {
    email,
    password,
  });
  return response.data;
}

export async function loginGoogleUser({ credential }) {
  const response = await api.post(`/user/login/google`, {
    credential,
  });
  return response.data;
}

export async function registerUser({ email, password, name }) {
  const response = await api.post(`/user/register`, {
    email,
    password,
    name,
  });
  return response.data;
}

export async function changePassword({ oldpassword, newpassword }) {
  try {
    const response = await api.post(`/user/password`, {
      oldpassword,
      newpassword,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function setPasswordRestore({ token, password }) {
  try {
    const response = await api.post(`/user/password/new`, {
      token,
      password,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function reqPasswordRestore({ email }) {
  try {
    const response = await api.post(`/user/password/restore`, {
      email,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function createPassword({ password, name, token }) {
  try {
    const response = await api.post(`/user/password/create`, {
      password,
      name,
      token,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}
