<script>
import router from "../../router";
import AliceLogoMark from '../../components/shared/AliceLogoMark';
import InstructionsSent from '../../layouts/password/InstructionsSent';
import PopUpCardModal from "../../components/shared/PopUpCardModal";

import { reqPasswordRestore } from "../../services/session";

export default {
    name: "ForgottenPassword",
    components: {
        AliceLogoMark,
        InstructionsSent,
        PopUpCardModal,
    },
    data() {
        return {
            password: null,
            loading: false,
            email: null,
            showInstructionsSent: false,
            textMsgError: '',
            isEmailValid: true,
            signUpClicked: false,
            showMsgEmailExists: false,
        };
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        handleEmailInputChange() {
            this.showMsgEmailExists = false;
        },
        redirectToSignIn() {
            router.push({ name: 'login' })
        },
        redirectToSignUp() {
            router.push({ name: 'sign-up' })
        },
        validateEmail() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!emailRegex.test(this.email)) {
                this.isEmailValid = false;
                return false;
            }

            this.isEmailValid = true;
            return true;
        },

        async findPassword() {
            this.signUpClicked = true;
            if (!this.validateEmail()) return;

            try {
                const res_data = await reqPasswordRestore({
                    email: this.email,
                });
                if (res_data.error === false) {
                    this.showInstructionsSent = true;
                } else {
                    if (res_data.message) {
                        // this.textMsgError = res_data.message;
                        // this.$refs.modalError.showModal();
                        this.showMsgEmailExists = true;
                    } else {
                        this.textMsgError = 'An error has occurred.';
                        this.$refs.modalError.showModal();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<template>
    <div class="verify-email-container pt-5">
        <section class="section-container">
            <div>
                <router-link to="/login">
                    <AliceLogoMark />
                </router-link>
                <div class="verify-email-text-container">
                    <template v-if="!showInstructionsSent">
                        <h1 class="title">Forgotten password?</h1>
                        <div class="px-5 pb-5">
                            <p class="subtitle pt-5" v-if="!showMsgEmailExists">Please enter your email address below for instructions.</p>
                            <p class="subtitle pt-5" v-else>It seems that email address you entered doesn’t exist in our system. <br />
                                Please try again, or ’Sign up’ using the link below.</p>
                        </div>

                        <form @submit.prevent="findPassword">
                            <div class="d-flex flex-column">
                                <input class="login-email" placeholder="Your work email" v-model="email" @input="handleEmailInputChange" />
                                <p v-if="!isEmailValid && signUpClicked" class="color-error-login">
                                    Please enter a valid e-mail address.
                                </p>
                                <input type="submit" class="send-button" value="Send" />
                            </div>
                        </form>

                    </template>
                    <template v-else>
                        <InstructionsSent :email="email" />
                    </template>
                </div>

                <div class="sign-or-container">
                    <div @click="redirectToSignIn" class="sign-text-container underline">
                        <h3 class="purple-underline">Sign in</h3>
                    </div>

                    <div class="or-text">
                        <h3>or</h3>
                    </div>

                    <div @click="redirectToSignUp" class="sign-text-container underline">
                        <h3 class="purple-underline">Sign up</h3>
                    </div>
                </div>

            </div>

            <PopUpCardModal ref="modalError" titleCard="Forgotten password" :textMsg="textMsgError" :isOk="true" />

            <footer class="footer-bobble-graph">
                <img class="w-100" src="../../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
            </footer>
        </section>
    </div>
</template>

<style src="../../assets/styles/views/password/ForgottenPassword.css"></style>
  